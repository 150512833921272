<template>
  <div class="content-menu content-menu-left ps">
    <ul class="list-unstyled" role="tablist">
      <!-- <button class="nav-link" :class="{'active': tab.showing}" 
        :id="tab.name + '-tab'" type="button" role="tab" :aria-selected="tab.showing" 
        @click="selectTab(tab)">{{tab.name}} 
        <span v-if="tab.name !== 'Search'" 
        class="tab-close-icon material-icons-outlined" 
        @click.prevent="closeTab(tab)">close</span></button> -->
      <li>
        <a
          href="javascript:void(0);"
          :class="{ active: tabs.details }"
          role="tab"
          :aria-selected="tabs.details"
          @click="selectTab('details')"
          >Details</a
        >
      </li>
      <div v-if="claim.reference !== undefined">
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.notes }"
            role="tab"
            :aria-selected="tabs.notes"
            @click="selectTab('notes')"
            >Notes
            <span v-if="claim.notes.length > 0"
              >({{ claim.notes.length }})</span
            ></a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.appeals }"
            role="tab"
            :aria-selected="tabs.appeals"
            @click="selectTab('appeals')"
            class="text-warning"
            >Appeals
            <span v-if="claim.appeals.length > 0"
              >({{ claim.appeals.length }})</span
            ></a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.vehicle }"
            role="tab"
            :aria-selected="tabs.vehicle"
            @click="selectTab('vehicle')"
            >Vehicle</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.history }"
            role="tab"
            :aria-selected="tabs.history"
            @click="selectTab('history')"
            >History</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.evidence }"
            role="tab"
            :aria-selected="tabs.evidence"
            @click="selectTab('evidence')"
            >Evidence
            <span v-if="claim.assets.length > 0"
              >({{ claim.assets.length }})</span
            ></a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.reminders }"
            role="tab"
            :aria-selected="tabs.reminders"
            @click="selectTab('reminders')"
            >Reminders</a
          >
        </li>
        <li class="divider"></li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.manage }"
            role="tab"
            :aria-selected="tabs.manage"
            @click="selectTab('manage')"
            >Manage</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.payments }"
            role="tab"
            :aria-selected="tabs.payments"
            @click="selectTab('payments')"
            >Payments
            <span v-if="claim.payments.length > 0"
              >({{ claim.payments.length }})</span
            ></a
          >
        </li>
        <li>
          <a
            href="javascript:void(0);"
            :class="{ active: tabs.logs }"
            role="tab"
            :aria-selected="tabs.logs"
            @click="selectTab('logs')"
            >Logs
            <span v-if="claim.logs.length > 0"
              >({{ claim.logs.length }})</span
            ></a
          >
        </li>
      </div>
      <div
        v-else
        style="width: 100%; text-align: center; margin-top: calc(50%)"
      >
        <div
          class="spinner-border"
          style="height: 4rem; width: 4rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </ul>
  </div>
  <div class="content-wrapper" style="margin-bottom: 200px">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="page-description d-flex align-items-center">
            <div class="page-description-content flex-grow-1">
              <h1>{{ claimReference.name }}</h1>
            </div>
            <div class="page-description-actions">
              <span
                v-if="collision.viewing > 0"
                class="badge badge-style-bordered badge-warning m-r-sm blink_me"
                ><span
                  v-if="claim.status === 'Locked'"
                  class="material-icons-outlined"
                  >visibility</span
                >
                {{ collision.viewing }} Users Viewing</span
              >
              <span class="badge badge-style-bordered" :class="statusBadge"
                ><span
                  v-if="claim.status === 'Locked'"
                  class="material-icons-outlined"
                  >lock</span
                >
                {{ (claim.status || "loading").toUpperCase() }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div
            v-show="claim.reference === undefined"
            style="width: 100%; text-align: center; margin-top: calc(50%)"
          >
            <div
              class="spinner-border"
              style="height: 4rem; width: 4rem"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div
            class="tab-pane fade"
            v-show="tabs.details"
            :class="{ 'show active': tabs.details }"
            id="detailsTab"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <!-- Type specific information here -->
            <parking-details v-if="claim.type === 'parking'" :claim="claim">
              <!-- <claim-details :claim="claim"/> -->
              <claim-details
                :claim="claim"
                :incidentTimestamp="incidentTimestamp"
              />
            </parking-details>
            <driveoff-details
              v-if="claim.type === 'driveoff' || claim.type === 'escalated'"
              :claim="claim"
              :client_id="client_id"
            >
              <claim-details
                :claim="claim"
                :incidentTimestamp="incidentTimestamp"
              />
            </driveoff-details>
          </div>

          <div
            class="tab-pane fade"
            v-show="tabs.notes"
            :class="{ 'show active': tabs.notes }"
            role="tabpanel"
          >
            <claim-notes
              v-if="claim.notes !== undefined"
              :claim="claim"
              :client_id="client_id"
              @create="addNote"
              @delete="deleteNote"
            />
          </div>
          <div
            class="tab-pane fade"
            v-show="tabs.appeals"
            :class="{ 'show active': tabs.appeals }"
            role="tabpanel"
          >
            <claim-appeals :claim="claim" :client_id="client_id" />
          </div>
          <div
            class="tab-pane fade"
            v-show="tabs.vehicle"
            :class="{ 'show active': tabs.vehicle }"
            role="tabpanel"
          >
            <claim-vehicle
              v-if="tabs.vehicle"
              :claim="claim"
              :client_id="client_id"
            />
          </div>
          <div
            class="tab-pane fade"
            v-show="tabs.history"
            :class="{ 'show active': tabs.history }"
            role="tabpanel"
          ></div>
          <div
            class="tab-pane fade"
            v-show="tabs.evidence"
            :class="{ 'show active': tabs.evidence }"
            role="tabpanel"
          ></div>
          <div
            class="tab-pane fade"
            v-show="tabs.reminders"
            :class="{ 'show active': tabs.reminders }"
            role="tabpanel"
          ></div>
          <div
            class="tab-pane fade"
            v-show="tabs.manage"
            :class="{ 'show active': tabs.manage }"
            role="tabpanel"
          ></div>
          <div
            class="tab-pane fade"
            v-show="tabs.payments"
            :class="{ 'show active': tabs.payments }"
            role="tabpanel"
          ></div>
          <div
            class="tab-pane fade"
            v-show="tabs.logs"
            :class="{ 'show active': tabs.logs }"
            role="tabpanel"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="claim.reference !== undefined"
    class="accordion claim-footer"
    id="actionsBox"
  >
    <div class="accordion-item">
      <h2 class="accordion-header" id="actionsBoxHeader">
        <!-- <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                  Claim Actions (Click to Open)
              </button> -->
        <span class="accordion-button bold"
          >Claim Actions<span
            class="material-icons-outlined float-right"
            style="cursor: pointer"
            data-bs-toggle="collapse"
            data-bs-target="#filters"
            aria-expanded="false"
            aria-controls="filters"
            @click="accordionCollapsed = !accordionCollapsed"
            >{{ accordionCollapsed ? "expand_more" : "expand_less" }}</span
          ></span
        >
      </h2>
      <div
        id="filters"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#actionsBox"
      >
        <div class="accordion-body">
          <claim-actions-driveoff :claim="claim" :client_id="client_id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import ClaimService from "../../../../services/claims.service";
import Timestamp from "../../../../components/Timestamp.vue";
import ClaimNotes from "./notes/ClaimNotes.vue";
import ParkingDetails from "./details/ParkingDetails.vue";
import ClaimDetails from "./ClaimDetails.vue";
import ClaimAppeals from "./appeals/ClaimAppeals.vue";
import ClaimVehicle from "./vehicle/ClaimVehicle.vue";
import ClaimActionsDriveoff from "./actions/ClaimActionsDriveoff.vue";
import DriveoffDetails from "./details/DriveoffDetails.vue";
import interact from "interactjs";
export default {
  name: "Claim",
  components: {
    Timestamp,
    ParkingDetails,
    ClaimDetails,
    ClaimNotes,
    ClaimAppeals,
    ClaimVehicle,
    ClaimActionsDriveoff,
    DriveoffDetails,
  },
  props: {
    claimReference: Object,
    client_id: Number | String,
  },
  data() {
    return {
      claim: {},
      tabs: {
        details: true,
        notes: false,
        appeals: false,
        vehicle: false,
        history: false,
        evidence: false,
        reminders: false,
        manage: false,
        payments: false,
        logs: false,
      },
      collision: {
        viewing: 2,
      },
      viewing: {
        appeal: null,
      },
      footer: {},
      firstLoad: true,
      accordionCollapsed: false,
    };
  },
  mounted() {
    this.loadClaim();
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e) {
      e = e || window.event;
      if (e.ctrlKey) {
        switch (e.key) {
          case "ArrowRight":
          case "ArrowDown":
            this.nextTab();
            break;
          case "ArrowLeft":
          case "ArrowUp":
            this.previousTab();
            break;
        }
      }
    },
    loadClaim() {
      ClaimService.getClaim(
        this.$store.state.auth.user.selectedClient,
        this.claimReference.id
      )
        .then((response) => {
          response.data.claim.payments = [];
          this.claim = response.data.claim;
          this.claim.notes.sort((a, b) => {
            return b.created_at - a.created_at;
          });
          if (this.firstLoad) {
            this.firstLoad = false;
            this.$nextTick(() => {
              interact(".claim-footer")
                .resizable({
                  // resize from all edges and corners
                  edges: { left: true, right: true },

                  listeners: {
                    move(event) {
                      let { x, y } = event.target.dataset;

                      x = (parseFloat(x) || 0) + event.deltaRect.left;
                      y = (parseFloat(y) || 0) + event.deltaRect.top;

                      Object.assign(event.target.style, {
                        width: `${event.rect.width}px`,
                        height: `${event.rect.height}px`,
                        minWidth: `${event.rect.width}px`,
                        minHeight: `${event.rect.height}px`,
                        transform: `translate(${x}px, ${y}px)`,
                      });

                      Object.assign(event.target.dataset, { x, y });
                    },
                  },
                  modifiers: [
                    // keep the edges inside the parent
                    interact.modifiers.restrictEdges({
                      outer: "parent",
                    }),

                    // minimum size
                    interact.modifiers.restrictSize({
                      min: { width: 100, height: 50 },
                    }),
                  ],

                  inertia: true,
                })
                .draggable({
                  listeners: {
                    start(event) {
                      console.log(event.type, event.target);
                    },
                    move(event) {
                      let { x, y } = event.target.dataset;

                      x = (parseFloat(x) || 0) + event.delta.x;
                      y = (parseFloat(y) || 0) + event.delta.y;

                      event.target.style.transform = `translate(${x}px, ${y}px)`;

                      Object.assign(event.target.dataset, { x, y });
                    },
                  },
                });
            });
          }
          this.loadPayments();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadPayments() {
      ClaimService.getClaimPayments(
        this.$store.state.auth.user.selectedClient,
        this.claimReference.id
      )
        .then((response) => {
          this.claim.payments = response.data.payments;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    addNote(note) {
      this.claim.notes.unshift(note);
    },
    deleteNote(note) {
      this.claim.notes = this.claim.notes.filter((n) => {
        return n.id !== note.id;
      });
    },
    nextTab() {
      let tabNames = Object.keys(this.tabs);
      let currentTab = null;

      Object.keys(this.tabs).forEach((t) => {
        if (this.tabs[t] === true) {
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      if (index + 1 > tabNames.length - 1) {
        index = 0;
      } else {
        index++;
      }

      this.selectTab(tabNames[index]);
    },
    previousTab() {
      let tabNames = Object.keys(this.tabs);
      console.log(tabNames);
      let currentTab = null;

      Object.keys(this.tabs).forEach((t) => {
        if (this.tabs[t] === true) {
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      console.log("Original Index:", index);
      if (index - 1 < 0) {
        index = tabNames.length - 1;
      } else {
        index--;
      }

      console.log(
        "Tab Name:",
        currentTab,
        "New Tab:",
        tabNames[index],
        "Index:",
        index
      );

      this.selectTab(tabNames[index]);
    },
  },
  computed: {
    claimType: function () {
      switch (this.claim.type) {
        case "driveoff":
          return "Drive Off";
        case "escalated":
          return "Drive Off (No Means of Payment)";
        case "nmop":
          return "No Means of Payment";
        case "parking":
          return "Parking";
      }
    },
    statusBadge: function () {
      switch (this.claim.status) {
        case "Cancelled":
        case "Locked":
          return "badge-danger";
        case "Paid":
        case "Paid on Site":
          return "badge-success";
        case "Keeper Appealed":
        case "Debt Collection":
          return "badge-warning";
        default:
          return "badge-primary";
      }
    },
    formattedDates: function () {
      let actualDates = this.claim.dates;
      // https://stackoverflow.com/a/43376967/2984964
      const toTitleCase = (phrase) => {
        return phrase
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };
      let formatted = [];
      for (const date in actualDates) {
        if (actualDates.hasOwnProperty(date)) {
          if (date.includes("date_") && actualDates[date] !== null) {
            let name = toTitleCase(
              date.replace("date_", "").split("_").join(" ")
            );
            let properDate = DateTime.fromSeconds(actualDates[date]).toFormat(
              "dd/MM/yyyy"
            );

            formatted.push({ name: name, date: properDate });
          }
        }
      }

      return formatted;
    },
    incidentTimestamp: function () {
      if (this.claim.type !== "parking") {
        if (this.claim.events[0] !== undefined) {
          return this.claim.events[0].created_at;
        }
        if (this.claim.incident_timestamp !== null) {
          return this.claim.incident_timestamp;
        } else {
          return this.claim.date_received;
        }
      } else {
        if (this.claim.events[0] !== undefined) {
          return this.claim.events[0].created_at;
        }
        return this.claim.date_received;
      }
    },
  },
};
</script>

<style scoped>
.claim-footer {
  position: fixed;
  bottom: 0px;
  left: 20%;
  right: 20%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 15px;
  min-width: 60%;
  max-width: 60%;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.badge {
  border-radius: 1.25em;
  font-size: 23px;
}
</style>
