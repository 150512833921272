<template>
  <div class="row">
    <div
      v-for="appeal in claim.appeals"
      :key="appeal.id + 'appeal'"
      class="col-12"
    >
      <div class="card widget widget-popular-blog">
        <div class="card-body">
          <div class="widget-popular-blog-container">
            <div class="widget-popular-blog-content">
              <span class="widget-popular-blog-title">
                Appeal From: {{ appeal.email }}
              </span>
              <span class="widget-popular-blog-text">
                {{ appeal.message }}
              </span>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <span class="widget-popular-blog-date">
            Received at
            <timestamp :time="appeal.created_at" format="HH:mm" /> on
            <timestamp :time="appeal.created_at" format="dd/MM/yyyy" />
          </span>
          <span class="widget-popular-blog-dage m-l-sm left-border"
            >Status:
            <span
              :class="{
                'text-danger': appeal.status === 'Rejected',
                'text-success': appeal.status === 'Accepted',
                'text-primary': appeal.status === 'Penidng',
              }"
              >{{ appeal.status }}</span
            ></span
          >
          <span class="widget-popular-blog-dage m-l-sm left-border"
            >POPLA Code: <span class="bold">{{ claim.popla_code }}</span></span
          >
          <button
            v-if="appeal.status === 'Pending'"
            class="btn btn-danger float-end m-l-sm"
          >
            Reject
          </button>
          <button
            v-if="appeal.status === 'Pending'"
            class="btn btn-success float-end"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timestamp from "../../../../../components/Timestamp.vue";
export default {
  name: "ClaimAppeals",
  components: {
    Timestamp,
  },
  props: {
    claim: Object,
    client_id: Number | String,
  },
};
</script>

<style scoped>
.left-border {
  border-left: 1px solid grey;
  padding-left: 10px;
}
</style>
