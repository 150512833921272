<template>
  <div class="row">
    <div class="col" style="max-height: 80vh">
      <div class="card todo-container" style="max-height: 80vh">
        <div class="row" style="max-height: 100%">
          <div
            class="col-xl-4 col-xxl-3"
            id="searchContainer"
            style="height: 100%; overflow: hidden"
          >
            <div class="todo-menu">
              <button
                type="button"
                class="btn btn-primary m-b-lg"
                style="width: 100%"
                @click="loadClaims(pagination.page)"
              >
                <span
                  v-show="searching"
                  class="spinner-border spinner-border-sm m-r-xxs"
                ></span>
                {{ $t("claims.processing.search") }}
              </button>

              <h5 class="todo-menu-title">Search</h5>
              <input
                type="text"
                v-on:keydown.enter="loadClaims"
                class="form-control form-control-solid m-b-sm"
                v-model="search.registration"
                :placeholder="
                  $t('claims.processing.vehicle-registration') + '...'
                "
              />
              <input
                type="text"
                v-on:keydown.enter="loadClaims"
                class="form-control form-control-solid m-b-sm"
                v-model="search.referenceNumber"
                :placeholder="$t('claims.processing.reference-number') + '...'"
              />
              <input
                type="text"
                v-on:keydown.enter="loadClaims"
                class="form-control form-control-solid m-b-lg"
                v-model="search.status"
                :placeholder="$t('claims.processing.status') + '...'"
              />

              <h5 class="todo-menu-title">Type</h5>
              <ul class="list-unstyled todo-status-filter m-b-lg">
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: types.all }"
                    @click="toggleType('all')"
                    ><i class="material-icons-outlined">format_list_bulleted</i
                    >{{ $t("claims.processing.all") }}</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: types.driveoff }"
                    @click="toggleType('driveoff')"
                    ><i class="material-icons-outlined">taxi_alert</i
                    >{{ $t("claims.processing.driveoff") }}</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: types.nmop }"
                    @click="toggleType('nmop')"
                    ><i class="material-icons-outlined">money_off</i
                    >{{ $t("claims.processing.nmop") }}</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: types.parking }"
                    @click="toggleType('parking')"
                    ><i class="material-icons-outlined">local_parking</i
                    >{{ $t("claims.processing.parking") }}</a
                  >
                </li>
              </ul>

              <h5 class="todo-menu-title">Groups</h5>
              <ul class="list-unstyled todo-status-filter m-b-lg">
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: groups.all }"
                    @click="toggleGroup('all')"
                    ><i class="material-icons-outlined">format_list_bulleted</i
                    >{{ $t("claims.processing.all") }}</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: groups.actionable }"
                    @click="toggleGroup('actionable')"
                    ><i class="material-icons-outlined">access_time_filled</i
                    >{{ $t("claims.processing.actionable") }}</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: groups.waiting }"
                    @click="toggleGroup('waiting')"
                    ><i class="material-icons-outlined">hourglass_bottom</i
                    >{{ $t("claims.processing.waiting") }}</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    :class="{ active: groups.nonactionable }"
                    @click="toggleGroup('nonactionable')"
                    ><i class="material-icons-outlined">block</i
                    >{{ $t("claims.processing.nonactionable") }}</a
                  >
                </li>
              </ul>

              <h5 class="todo-menu-title">Preferences</h5>
              <div class="todo-preferences-filter">
                <div class="todo-preferences-item">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="preferences.hideLocked"
                    value=""
                    id="hideLockedCheck"
                  />
                  <label class="form-check-label" for="hideLockedCheck">
                    {{ $t("claims.processing.hide-locked") }}
                  </label>
                </div>
                <div class="todo-preferences-item">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="preferences.hasPayments"
                    value=""
                    id="hasPaymentsCheck"
                  />
                  <label class="form-check-label" for="hasPaymentsCheck">
                    {{ $t("claims.processing.has-payments") }}
                  </label>
                </div>
                <div class="todo-preferences-item">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="preferences.hasNotes"
                    value=""
                    id="hasNotesCheck"
                  />
                  <label class="form-check-label" for="hasNotesCheck">
                    {{ $t("claims.processing.has-notes") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-8 col-xxl-9"
            id="claimContainer"
            style="height: 100%"
          >
            <div
              v-show="searching"
              style="width: 100%; text-align: center; margin-top: calc(50%)"
            >
              <div
                class="spinner-border"
                style="height: 4rem; width: 4rem"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <pagination
              v-show="!searching"
              class="m-t-lg m-r-sm"
              :config="pagination"
              @search="(page) => loadClaims(page)"
            ></pagination>
            <div v-show="!searching" class="todo-list">
              <ul class="list-unstyled">
                <li
                  class="todo-item"
                  v-for="claim in claims"
                  :key="'claim-' + claim.reference"
                >
                  <div class="todo-item-content">
                    <span class="todo-item-title">
                      {{ claim.reference }}
                      <span
                        class="badge badge-style-light rounded-pill badge-warning"
                        >{{ claim.type }}</span
                      >
                      <span
                        class="badge badge-style-bordered"
                        :class="claim.class"
                        >{{ claim.status }}</span
                      >
                      <span
                        v-if="claim.note_count > 0"
                        class="badge badge-style-bordered badge-info"
                        >{{
                          $t("claims.processing.notes", claim.note_count, {
                            count: claim.note_count,
                          })
                        }}</span
                      >
                      <span
                        v-if="claim.appeal_count > 0"
                        class="badge badge-style-bordered badge-warning"
                        >{{
                          $t("claims.processing.appeals", claim.appeal_count, {
                            count: claim.appeal_count,
                          })
                        }}</span
                      >
                      <span
                        v-if="claim.payment_count > 0"
                        class="badge badge-style-bordered badge-success"
                        >{{
                          $t(
                            "claims.processing.payments",
                            claim.payment_count,
                            { count: claim.payment_count }
                          )
                        }}</span
                      >
                    </span>
                    <span class="todo-item-subtitle">
                      <span class="vrm bold number-plate-gbr m-r-lg">{{
                        claim.vehicle.plate
                      }}</span>
                      {{ $t("claims.processing.received-on") }}
                      <timestamp
                        :time="claim.date_received"
                        format="dd/MM/yyyy"
                      ></timestamp>
                      {{
                        $t("claims.processing.by", { site: claim.site.name })
                      }}
                      <span
                        v-if="claim.progress_message !== null"
                        class="m-l-lg"
                        >{{ $t("claims.processing.progress-message") }}:
                        {{ claim.progress_message }}</span
                      >
                    </span>
                  </div>
                  <div class="todo-item-actions">
                    <button
                      class="btn btn-sm btn-primary"
                      @click="selectClaim(claim)"
                    >
                      Process Claim
                      <i class="material-icons-outlined no-m">launch</i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <pagination
              v-show="!searching"
              class="m-b-lg m-r-sm"
              :config="pagination"
              @search="(page) => loadClaims(page)"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Pagination.vue";
import Timestamp from "../../../components/Timestamp.vue";
import ClaimService from "../../../services/claims.service";
export default {
  components: { Pagination, Timestamp },
  name: "Search",
  data() {
    return {
      search: {
        registration: null,
        referenceNumber: null,
        status: null,
      },
      types: {
        all: true,
        driveoff: false,
        nmop: false,
        parking: false,
      },
      groups: {
        all: true,
        actionable: false,
        waiting: false,
        nonactionable: false,
      },
      preferences: {
        hideLocked: false,
        hasPayments: false,
        hasNotes: false,
      },
      claims: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0,
      },
      ps: null,
      sps: null,
      searching: false,
    };
  },
  mounted() {
    this.ps = new PerfectScrollbar("#claimContainer");
    this.sps = new PerfectScrollbar("#searchContainer");
    let rawSavedConfig = localStorage.getItem("claimFilters");
    console.log(rawSavedConfig);
    if (rawSavedConfig !== null) {
      try {
        let savedConfig = JSON.parse(rawSavedConfig);
        this.search = savedConfig.search;
        this.types = savedConfig.types;
        this.groups = savedConfig.groups;
        this.preferences = savedConfig.preferences;
        console.log(savedConfig);
      } catch (error) {
        console.log(error);
      }
    }
    this.loadClaims();
    this.ps.update();
    this.sps.update();
  },
  methods: {
    async loadClaims(page = 1) {
      this.searching = true;
      localStorage.setItem(
        "claimFilters",
        JSON.stringify({
          search: this.search,
          types: this.types,
          groups: this.groups,
          preferences: this.preferences,
        })
      );
      ClaimService.getClaims(this.$store.state.auth.user.selectedClient, {
        page: page,
        search: this.search,
        types: this.types,
        groups: this.groups,
        preferences: this.preferences,
      })
        .then((response) => {
          this.ps.update();
          this.claims = response.data.claims;
          this.ps.update();
          this.claims.forEach((claim) => {
            switch (claim.type) {
              case "driveoff":
                claim.type = "Drive Off";
                break;
              case "escalated":
                claim.type = "Drive Off (No Means of Payment)";
                break;
              case "nmop":
                claim.type = "No Means of Payment";
                break;
              case "parking":
                claim.type = "Parking";
                break;
            }

            switch (claim.status) {
              case "Cancelled":
                claim.class = "badge-danger";
                break;
              case "Paid":
              case "Paid on Site":
                claim.class = "badge-success";
                break;
              case "Debt Collection":
                claim.class = "badge-warning";
                break;
              default:
                claim.class = "badge-primary";
                break;
            }

            if (claim.progress_message !== null) {
              if (claim.progress_message.length < 1)
                claim.progress_message = null;
            }
          });
          this.pagination = response.data.pagination;
          this.pagination.maxPage = this.pagination.lastPage;
          this.searching = false;
          this.ps.update();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleType(type) {
      switch (type) {
        case "all":
          if (this.types.all === true) {
            this.types.all = false;
          } else if (this.types.all === false) {
            this.types.all = true;
            this.types.driveoff = false;
            this.types.nmop = false;
            this.types.parking = false;
          }
          break;
        default:
          this.types[type] = !this.types[type];
          if (this.types.driveoff && this.types.nmop && this.types.parking) {
            this.types.all = true;
            this.types.driveoff = false;
            this.types.nmop = false;
            this.types.parking = false;
          } else {
            this.types.all = false;
          }
          break;
      }
    },
    toggleGroup(group) {
      switch (group) {
        case "all":
          if (this.groups.all === true) {
            this.groups.all = false;
          } else if (this.groups.all === false) {
            this.groups.all = true;
            this.groups.actionable = false;
            this.groups.waiting = false;
            this.groups.nonactionable = false;
          }
          break;
        default:
          this.groups[group] = !this.groups[group];
          if (
            this.groups.actionable &&
            this.groups.waiting &&
            this.groups.nonactionable
          ) {
            this.groups.all = true;
            this.groups.actionable = false;
            this.groups.waiting = false;
            this.groups.nonactionable = false;
          } else {
            this.groups.all = false;
          }
          break;
      }
    },
    selectClaim(claim) {
      this.$emit("claim", claim);
    },
  },
  computed: {
    hideLocked: function () {
      return this.preferences.hideLocked;
    },
  },
  watch: {
    hideLocked: function (o, n) {
      this.loadClaims();
    },
  },
};
</script>

<style scoped>
.todo-menu .todo-label-filter a {
  margin-right: 10px;
}
#claimContainer {
  position: relative;
  overflow: hidden;
}
#searchContainer {
  position: relative;
  overflow: hidden;
}
.vrm {
  padding: 5px 7.5px;
  background-color: yellow;
  border-radius: 5px;
  color: black !important;
}
.number-plate-gbr {
  background-image: url(/images/uk-plate-logo.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding-left: 2rem !important;
}
</style>
