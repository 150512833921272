<template>
  <driveoff-pending
    v-if="claim.status === 'Pending'"
    :claim="claim"
    :client_id="client_id"
  />
</template>

<script>
import DriveoffPending from "./driveoff/DriveoffPending.vue";
export default {
  name: "ClaimActionsDriveoff",
  components: {
    DriveoffPending,
  },
  props: {
    claim: Object,
    client_id: Number | String,
  },
  data() {},
};
</script>

<style scoped></style>
